<template>
  <div class="reservation-column-group-input-form">
    <div class="row name">
      <text-input-form
        :requiredFlg="true"
        :maxlength="10"
        :placeholder="'10文字まで'"
        :styles="{ width: '265px' }"
        v-model="reservationColumnGroup.name"
        >予約列グループ名</text-input-form
      >
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'

export default {
  name: 'ReservationColumGroupInputForm',

  components: {
    TextInputForm
  },

  props: {
    reservationColumnGroup: { type: Object }
  }
}
</script>

<style lang="scss" scoped>
.reservation-column-group-input-form {
  > .row ~ .row {
    margin-top: 25px;
  }
}
</style>
