<template>
  <div class="color-input-form">
    <div class="label">
      <div class="text"><slot></slot></div>
      <hint-balloon v-if="note" :note="note" />
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="field">
      <div class="normal">
        <div
          class="color-display item"
          :style="{ backgroundColor: color }"
        ></div>
        <div class="hashtag item">#</div>
        <base-text-box
          class=" item"
          :value="hex6char"
          :maxlength="6"
          :styles="{ width: '90px', height: '33px' }"
          @input="inputText"
          @blur="checkColor"
        />
        <div class="color-chart-button item" @click="isActive = !isActive">
          <img
            src="@/assets/images/box_menu_pumpkin_32px.png"
            class="box-menu-icon"
            width="20px"
            height="20px"
            alt="box-menu-pumpkin"
          />
        </div>
        <!-- <base-color-box class="item" :value="color" @input="inputColor" /> -->
      </div>
      <div class="error" v-if="colorErrorFlg">
        <span>有効なカラーコードを入力してください</span>
      </div>
      <transition name="fade">
        <base-color-chart
          class="chart"
          v-if="isActive"
          :value="color"
          :colorSets="colorSets"
          @click="inputColor"
        />
      </transition>
    </div>
    <div
      class="overlay"
      v-if="isActive"
      @click.self="isActive = !isActive"
    ></div>
  </div>
</template>

<script>
import BaseColorChart from '@/components/parts/atoms/BaseColorChart'
// import BaseColorBox from '@/components/parts/atoms/BaseColorBox'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import { VALID_COLOR_CODE_REGEX } from '@/utils/define'

export default {
  name: 'ColorInputForm',

  components: {
    BaseColorChart,
    // BaseColorBox,
    BaseTextBox,
    HintBalloon
  },

  props: {
    color: { type: String, default: '#ef6c00' },
    requiredFlg: { type: Boolean, default: false },
    colorSets: { type: Array },
    note: { type: String, default: '' }
  },

  data() {
    return {
      isActive: false,
      hex6char: this.color.replace('#', '')
    }
  },

  computed: {
    colorErrorFlg() {
      return VALID_COLOR_CODE_REGEX.test(this.color) ? false : true
    },
    hex6charErrorFlg() {
      const colorByText = '#' + this.hex6char
      return VALID_COLOR_CODE_REGEX.test(colorByText) ? false : true
    }
  },

  watch: {
    color: function(color) {
      this.hex6char = this.color.replace('#', '')
    }
  },

  methods: {
    checkColor() {
      if (this.hex6charErrorFlg) {
        this.hex6char = this.color.replace('#', '')
      }
    },
    inputText(text) {
      this.hex6char = text
      const colorByText = '#' + text
      if (colorByText.length === 7) {
        this.inputColor(colorByText)
      }
    },
    inputColor(color) {
      if (VALID_COLOR_CODE_REGEX.test(color)) {
        this.hex6char = color.replace('#', '')
        this.$emit('input', color)
      }
    }
  }
}
</script>

<style lang="scss" scpoed>
.color-input-form {
  display: flex;
  align-items: flex-start;
  > .label {
    display: flex;
    align-items: center;
    width: 240px;
    height: 33px;
    > .text {
      font-size: 15px;
    }
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .field {
    position: relative;
    > .normal {
      display: flex;
      align-items: center;
      > .item ~ .item {
        margin-right: 5px;
      }
      > .color-display {
        width: 33px;
        height: 33px;
        margin-right: 15px;
        border-radius: 50%;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
      }
      > .color-chart-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        box-sizing: border-box;
        border-radius: 6px;
        border: solid 2px #{$light-grey};
        background-color: #{$white};
        &:hover {
          opacity: 0.8;
          background-color: #{$plus_ee_gray};
        }
        &:active {
          position: relative;
          top: 2px;
          height: 31px;
        }
        > .box-menu-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
    > .error {
      position: absolute;
      z-index: 10;
      top: 35px;
      width: 300px;
      color: #{$tomato};
      font-size: 12px;
    }
    > .chart {
      position: absolute;
      z-index: 1100;
      top: 38px;
      left: 47px;
    }
  }
}
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-to,
.fade-enter {
  opacity: 0;
}
</style>
