<template>
  <div class="reservation-column-input-form">
    <div class="row name">
      <text-input-form
        :requiredFlg="true"
        :maxlength="10"
        :placeholder="'10文字まで'"
        :styles="{ width: '265px' }"
        v-model="reservationColumn.name"
        >予約列名</text-input-form
      >
    </div>
    <div class="row group">
      <select-box-form
        :requiredFlg="true"
        :selectData="reservationColumnGroups"
        v-model.number="reservationColumn.reservationColumnGroupId"
        >所属グループ
      </select-box-form>
    </div>
    <div class="row color">
      <color-input-form
        :requiredFlg="true"
        :colorSets="colorSets"
        :color="reservationColumn.color"
        v-model="reservationColumn.color"
        >カラー</color-input-form
      >
    </div>
    <div class="row staff">
      <select-box-form
        :note="staffHint"
        :requiredFlg="false"
        :selectData="staffs"
        @input="inputStaff"
        v-model.number="reservationColumn.staffId"
        >スタッフ
      </select-box-form>
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import ColorInputForm from '@/components/parts/molecules/ColorInputForm.vue'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'

export default {
  name: 'ReservationColumInputForm',

  components: {
    TextInputForm,
    SelectBoxForm,
    ColorInputForm
  },

  props: {
    reservationColumn: { type: Object },
    reservationColumnGroups: { type: Array },
    colorSets: { type: Array },
    staffs: { type: Array }
  },
  data() {
    return {
      staffHint:
        '設定すると該当スタッフが勤務し他の予約が入っていない時のみネットからの予約を取得します。'
    }
  },
  methods: {
    inputStaff() {
      this.$emit('input-staff')
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-column-input-form {
  > .row ~ .row {
    margin-top: 25px;
  }
}
</style>
