<template>
  <div class="reservation-column-setting">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ReservationColumnSetting'
}
</script>

<style lang="scss" scoped>
.reservation-column-setting {
  width: 100%;
  min-width: 614px;
  text-align: left;
}
</style>
