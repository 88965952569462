<template>
  <div class="reservation-column-group-show">
    <div class="link">
      <base-back-link @click="openListPage" />
    </div>
    <div class="title">予約列グループ詳細</div>
    <div class="contents">
      <div class="button-wrap">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="clickDelete"
          >削除</base-button-small-red
        >
      </div>
      <div class="detail-wrap">
        <div class="heading">基本項目</div>
        <div class="detail">
          <show-data-list
            :displayKeys="displayGroupKes"
            :keys="groupKeys"
            :data="displayGroup"
          />
        </div>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="cancelDelete"
      @decision="decideDelete"
      @close="closeAlert"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationColumnShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      displayGroupKes: ['予約列グループ名'],
      groupKeys: ['name'],
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getReservationColumnGroup: 'reservationColumnGroups/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayGroup() {
      return this.getReservationColumnGroup(this.id)
    }
  },

  methods: {
    initializeAlert() {
      this.alertFlg = false
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
    },
    openListPage() {
      this.$router.push({ name: 'reservation-column-groups-tab' })
    },
    openEditPage() {
      this.$router.push({
        name: 'reservation-column-group-edit',
        params: this.id
      })
    },
    clickDelete() {
      this.initializeAlert()
      this.alertFlg = true
    },
    cancelDelete() {
      this.alertFlg = false
    },
    async decideDelete() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'reservationColumnGroups/delete',
        this.id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'used in reservationColumn') {
          this.popupMessage = '予約列で使用されているため削除できません'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
      this.openListPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-column-group-show {
  > .link {
    margin-left: -26px;
  }
  > .title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin-top: 20px;
  }
  > .contents {
    margin-top: 20px;
    > .button-wrap {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .detail-wrap {
      > .heading {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
        margin-top: 20px;
      }
      > .detail {
        margin-top: 25px;
      }
    }
  }
}
</style>
