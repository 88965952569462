<template>
  <div class="reservation-column-group-new">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">予約列グループ登録</div>
      <div class="contents">
        <div class="input-wrap">
          <div class="heading">基本項目</div>
          <div class="content-area">
            <reservation-column-group-input-form
              class="input-form"
              :reservationColumnGroup="newReservationColumnGroup"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="openListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="createData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import ReservationColumnGroupInputForm from '@/components/parts/organisms/ReservationColumnGroupInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationColumnGroupNew',

  components: {
    ReservationColumnGroupInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      newReservationColumnGroup: { name: '' },
      alertFlg: false,
      waitFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる']
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.mixinInputData = this.newReservationColumnGroup
    this.mixinSetInitialData()
  },

  methods: {
    openListPage() {
      this.$router.push({ name: 'reservation-column-groups-tab' })
    },
    async createData() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'reservationColumnGroups/create',
        this.newReservationColumnGroup
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '登録に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.openListPage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-column-group-new {
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .input-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .content-area {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
