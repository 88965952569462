<template>
  <div class="reservation-column-show">
    <div class="link">
      <base-back-link @click="backListPage" />
    </div>
    <div class="title">予約列詳細</div>
    <div class="contents">
      <div class="button-wrap">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="clickDelete"
          >削除</base-button-small-red
        >
      </div>
      <div class="detail-wrap">
        <div class="heading">基本項目</div>
        <div class="detail">
          <show-data-list
            :displayKeys="displayColumnKes"
            :keys="columnKeys"
            :data="displayColumn"
          />
        </div>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="cancelDelete"
      @decision="decideDelete"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationColumnShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      displayColumnKes: ['予約列名', '所属グループ', 'カラー', 'スタッフ'],
      columnKeys: ['name', 'groupName', 'color', 'staff'],
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getReservationColumn: 'reservationColumns/getDataById',
      getReservationColumnGroup: 'reservationColumnGroups/getDataById',
      getStaff: 'staffs/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayColumn() {
      const reservationColumn = this.getReservationColumn(this.id)
      if (reservationColumn) {
        const groupId = reservationColumn.reservationColumnGroupId
        return {
          id: this.id,
          name: reservationColumn.name,
          groupName: this.getReservationColumnGroup(groupId).name,
          color: reservationColumn.color,
          staff: this.staffName(reservationColumn.staffId)
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    initializeAlert() {
      this.alertFlg = false
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
    },
    staffName(staffId) {
      const staff = this.getStaff(staffId)
      return staffId === 0 ? '' : staff.lastName + ' ' + staff.firstName
    },
    backListPage() {
      this.$router.push({ name: `reservation-columns-tab` })
    },
    openEditPage() {
      this.$router.push({ name: 'reservation-column-edit', params: this.id })
    },
    clickDelete() {
      this.initializeAlert()
      this.alertFlg = true
    },
    cancelDelete() {
      this.alertFlg = false
    },
    async decideDelete() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'reservationColumns/delete',
        this.id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '削除しました'
        this.buttons = ['閉じる']
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'used in reservationPurpose') {
          this.popupMessage = '来院目的で使用されているため削除できません'
        } else if (result === 'used in reservation') {
          this.popupMessage = '本日以降の予約で使用されているため削除できません'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    },
    closePopup() {
      this.$router.push({ name: `reservation-columns-tab` })
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-column-show {
  > .link {
    margin-left: -26px;
  }
  > .title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin-top: 20px;
  }
  > .contents {
    margin-top: 20px;
    > .button-wrap {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .detail-wrap {
      margin-top: 16px;
      > .heading {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
      }
      > .detail {
        margin-top: 25px;
      }
    }
  }
}
</style>
