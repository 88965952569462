<template>
  <div class="reservation-column-new">
    <validation-observer
      class="validation-observer"
      v-slot="{ invalid }"
      ref="form"
    >
      <div class="title">予約列登録</div>
      <div class="contents">
        <div class="input-wrap">
          <div class="heading">基本項目</div>
          <div class="content-area">
            <reservation-column-input-form
              class="input-form"
              :reservationColumn="newReservationColumn"
              :reservationColumnGroups="reservationColumnGroups"
              :staffs="staffs"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white
            class="button"
            @click="$router.push({ name: `reservation-columns-tab` })"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || !allValidFlg || waitFlg"
            @click="createData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import ReservationColumnInputForm from '@/components/parts/organisms/ReservationColumnInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { VALID_COLOR_CODE_REGEX } from '@/utils/define'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationColumnNew',

  components: {
    ReservationColumnInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      newReservationColumn: {
        name: '',
        reservationColumnGroupId: '',
        color: '#ef6c00',
        staffId: 0
      },
      reservationColumnGroups: this.$store.getters[
        'reservationColumnGroups/selectData'
      ],
      staffs: this.$store.getters['staffs/selectDataZero'],
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる']
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    groupIdValidFlg() {
      return this.reservationColumnGroups.some(
        o => o.id === this.newReservationColumn.reservationColumnGroupId
      )
    },
    colorValidFlg() {
      return VALID_COLOR_CODE_REGEX.test(this.newReservationColumn.color)
    },
    allValidFlg() {
      return this.groupIdValidFlg && this.colorValidFlg
    }
  },

  created() {
    this.mixinInputData = this.newReservationColumn
    this.mixinSetInitialData()
  },

  methods: {
    async resetReservationColumnGroups() {
      this.newReservationColumn.reservationColumnGroupId = ''
      this.reservationColumnGroups = this.$store.getters[
        'reservationColumnGroups/selectData'
      ]
    },
    async createData() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'reservationColumns/create',
        this.newReservationColumn
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'no reservationColumnGroup') {
          this.popupMessage =
            '登録に失敗しました。\n選択した予約列グループは既に削除されています。\n他の予約列グループを選択して下さい。'
          this.resetReservationColumnGroups()
        } else {
          this.popupMessage = '登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: `reservation-columns-tab` })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-column-new {
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .input-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .content-area {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
