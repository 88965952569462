<template>
  <div class="reservation-column-groups-tab">
    <div class="title">予約列管理</div>
    <div class="contents">
      <div class="tab-wrap">
        <base-setting-tab-title
          v-for="titleObj in tabTitles"
          :key="titleObj.id"
          :title="titleObj.title"
          :link="titleObj.link"
          :names="titleObj.names"
        />
      </div>
      <div class="button-wrap">
        <base-button-register
          v-if="lookOnlyFlg === 0"
          :text="'予約列グループ登録'"
          @click="$router.push({ path: 'reservation-column-groups/new' })"
        />
      </div>
      <div class="list-wrap">
        <div class="heading">予約列グループ一覧</div>
        <list-table
          :headerData="headers"
          :propertyData="properties"
          :bodyData="displayReservationColumnGroups"
          :directOrderFlg="true"
          @click="
            id => $router.push({ path: `reservation-column-groups/${id}` })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import ListTable from '@/components/parts/organisms/ListTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationColumnGroupsTab',

  components: {
    BaseSettingTabTitle,
    BaseButtonRegister,
    ListTable
  },

  data() {
    return {
      tabTitles: [
        {
          id: 1,
          title: '予約列',
          link: 'reservation-columns',
          names: ['reservation-columns-tab']
        },
        {
          id: 2,
          title: '予約列グループ',
          link: 'reservation-column-groups',
          names: ['reservation-column-groups-tab']
        }
      ],
      headers: ['予約列グループ名'],
      properties: ['name']
    }
  },

  computed: {
    ...mapGetters({
      staffReservationColumns: 'staffReservationColumns/getData',
      getColumn: 'reservationColumns/getDataById',
      getGroup: 'reservationColumnGroups/getDataById',
      reservationColumnGroups: 'reservationColumnGroups/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayReservationColumnGroups() {
      return [
        ...new Set(
          this.staffReservationColumns
            .map(v => {
              return this.getGroup(
                this.getColumn(v.reservationColumnId).reservationColumnGroupId
              )
            })
            .concat(this.reservationColumnGroups)
        )
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-column-groups-tab {
  > .title {
    font-size: 20px;
    font-weight: bold;
  }
  > .contents {
    margin-top: 20px;
    margin-bottom: 24px;
    > .tab-wrap {
      display: flex;
      flex-direction: row;
      height: 26px;
      box-sizing: border-box;
      border-bottom: 1px solid;
      border-color: #{$light-grey};
    }
    > .button-wrap {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
    > .list-wrap {
      margin-top: 16px;
      > .heading {
        margin-bottom: 16px;
        font-size: 15px;
        font-weight: bold;
      }
      ::v-deep .list-table .table-body {
        max-height: 560px;
      }
    }
  }
}
</style>
